// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-item-js": () => import("/opt/build/repo/src/templates/blog-item.js" /* webpackChunkName: "component---src-templates-blog-item-js" */),
  "component---src-templates-portfolio-item-js": () => import("/opt/build/repo/src/templates/portfolio-item.js" /* webpackChunkName: "component---src-templates-portfolio-item-js" */),
  "component---src-templates-portfolio-item-old-js": () => import("/opt/build/repo/src/templates/portfolio-item-old.js" /* webpackChunkName: "component---src-templates-portfolio-item-old-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("/opt/build/repo/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-workshop-js": () => import("/opt/build/repo/src/pages/workshop.js" /* webpackChunkName: "component---src-pages-workshop-js" */)
}

