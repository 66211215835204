export const pages = [
	{
		name: 'portfolio',
	},
	{
		name: 'about',
	},
	{
		name: 'blog',
	},
	// {
	// 	name: 'r&d',
	// 	url: 'workshop'
	// },
	{
		name: 'contact',
		url: '#contact'
	},
]

export const socialMedia = [
	{
		url: 'http://codepen.io/ryanfiller89/',
		name: 'Codepen',
		color: '#fcd000'
	},
	{
		url: 'https://github.com/ryanfiller',
		name: 'Github',
		// color: '#6cc644'
		color: '#24292e'
	},
	{
		url: 'https://www.linkedin.com/in/ryanfiller',
		name: 'LinkedIn',
		color: '#0077b5'
	},
	// {
	// 	url: 'https://www.facebook.com/ryan.filler',
	// 	name: 'Facebook',
	// 	color: '#3b5998'
	// },
	{
		url: 'https://twitter.com/ryanfiller_',
		name: 'Twitter',
		color: '#1da1f2'
	},
	{
		url: 'https://www.instagram.com/ryanfiller_/',
		name: 'Instagram',
		color: '#bc2a8d'
	},
	{
		url: 'https://dribbble.com/ryanoh',
		name: 'Dribbble',
		color: '#ea4c89'
	},
]